import React from "react";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
import Blurb from "../../../../components/blurb/blurb.component";
import { colors } from "../../../../infrastructure/theme/colors";

const Hero = () => {
  return (
    <FlexibleDiv
      boxedLayout
      paddingVertical="6rem"
      background={`linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.1&auto=format&fit=crop&w=1350&q=80')`}
      backgroundSize="cover"
      backgroundPosition="center"
      color={colors.text.white}
    >
      <FlexibleDiv
        paddingVertical="0"
        flexDirection="column"
        alignItems="center"
        width="80%"
      >
        <Blurb width="100%" alignItems="center" textAlign="center">
          <h1>Shaping the Future of Tech Education</h1>
          <p style={{ fontSize: "1.2rem", lineHeight: "1.8" }}>
            We're on a mission to transform tech education and create opportunities
            for the next generation of digital innovators.
          </p>
        </Blurb>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

export default Hero;
