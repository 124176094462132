import React, { useRef } from "react";
import Blurb from "../../../../components/blurb/blurb.component";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
// import { VerticalCard } from "../../../../components/verticalCard/verticalCard.component";
// import H1 from "../../../../assets/images/h1.png";
import { CardSectionWrapper } from "./HireRealExpert.styles";
import { SectionCards } from "../../../../components/sectionCards/sectionCards.component";
// import { Spacer } from "../../../../components/spacer/spacer.component";
import { colors } from "../../../../infrastructure/theme/colors";
import { SlUserUnfollow as User } from "react-icons/sl";
import { GiTimeTrap as Time } from "react-icons/gi";
import { CgTimelapse as WastingTime } from "react-icons/cg";
import { BsPatchCheck as Reliable } from "react-icons/bs";
import { GiTakeMyMoney as WastingMoney } from "react-icons/gi";
import { GiDeadEye as GreatIdea } from "react-icons/gi";
// import { useParallax } from "react-scroll-parallax";

const HireRealExpert = () => {
  const targetContainer = useRef(null);
  // const CardContainerEffect = useParallax({
  //   scale: [-5,],
  //   targetElement: targetContainer.current,
  // });

  // const Card1Effect = useParallax({
  //     speed: 100,
  //   targetElement: targetContainer.current,
  // });
  // const Card2Effect = useParallax({
  //   speed: 100,
  //   targetElement: targetContainer.current,
  // });
  // const Card3Effect = useParallax({
  //   speed: 100,
  //   targetElement: targetContainer.current,
  // });
  // const Card4Effect = useParallax({
  //   speed: 100,
  //   targetElement: targetContainer.current,
  // });
  // const Card5Effect = useParallax({
  //   speed: 100,
  //   targetElement: targetContainer.current,
  // });
  // const Card6Effect = useParallax({
  //   speed: 100,
  //   targetElement: targetContainer.current,
  // });

  const CardData = [
    {
      title: "Don't communicate with you?",
      Icon: User,
      // reference: Card1Effect.ref,
    },
    {
      title: "Make big promises, but can't deliver",
      Icon: Reliable,
      // reference: Card2Effect.ref,
    },
    {
      title: "Lack the skill to execute your great idea?",
      Icon: GreatIdea,
      // reference: Card3Effect.ref,
    },
    {
      title: "Take forever to get the job done?",
      Icon: WastingTime,
      // reference: Card4Effect.ref,
    },
    {
      title: "Waste your time on endless revisions?",
      Icon: Time,
      // reference: Card5Effect.ref,
    },
    {
      title: "Cost you money and don't bring results?",
      Icon: WastingMoney,
      // reference: Card6Effect.ref,
    },
  ];

  return (
    <FlexibleDiv
      justifyContent="center"
      paddingVertical="3rem"
      boxedLayout
      background={colors.bg.secondary}
      ref={targetContainer}
      id="HireExpert"
    >
      <Blurb width="70%">
        <h5>Hire A Real Expert</h5>
        <h3>Are you tired of agencies and software developers that</h3>
      </Blurb>
      <CardSectionWrapper /*ref={CardContainerEffect.ref}*/>
        {CardData.map(({ title, Icon },idx) => (
          <SectionCards
          key={idx}
            productName={"Happy Hour"}
            title={title}
            Icon={Icon}
            // reff={reference}
          />
        ))}
      </CardSectionWrapper>
    </FlexibleDiv>
  );
};

export default HireRealExpert;
