import React from "react";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
import Blurb from "../../../../components/blurb/blurb.component";
import { colors } from "../../../../infrastructure/theme/colors";

const Team = () => {
  const teamMembers = [
    {
      name: "Sarah Johnson",
      role: "CEO & Founder",
      image: "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-4.0.1&auto=format&fit=crop&w=634&q=80",
    },
    {
      name: "Michael Chen",
      role: "Head of Education",
      image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.1&auto=format&fit=crop&w=1350&q=80",
    },
    {
      name: "Amanda Williams",
      role: "Tech Lead",
      image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-4.0.1&auto=format&fit=crop&w=634&q=80",
    },
  ];

  return (
    <FlexibleDiv boxedLayout paddingVertical="5rem">
      <FlexibleDiv
        paddingVertical="0"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Blurb width="70%" alignItems="center" textAlign="center">
          <h3>Our Leadership Team</h3>
          <p>Meet the passionate individuals driving our mission forward</p>
        </Blurb>
        <FlexibleDiv
          paddingVertical="3rem"
          justifyContent="center"
          flexWrap="wrap"
          gap="2rem"
        >
          {teamMembers.map((member, index) => (
            <FlexibleDiv
              key={index}
              flexDirection="column"
              alignItems="center"
              width="300px"
            >
              <div
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  marginBottom: "1rem",
                }}
              >
                <img
                  src={member.image}
                  alt={member.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <h4 style={{ marginBottom: "0.5rem" }}>{member.name}</h4>
              <p style={{ color: colors.text.secondary }}>{member.role}</p>
            </FlexibleDiv>
          ))}
        </FlexibleDiv>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

export default Team; 