import React from "react";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
import { colors } from "../../../../infrastructure/theme/colors";

const Stats = () => {
  const stats = [
    { number: "1000+", label: "Students Trained" },
    { number: "95%", label: "Employment Rate" },
    { number: "50+", label: "Industry Partners" },
    { number: "20+", label: "Tech Courses" },
  ];

  return (
    <FlexibleDiv
      boxedLayout
      paddingVertical="4rem"
      background={colors.bg.primary}
    >
      <FlexibleDiv
        paddingVertical="0"
        justifyContent="space-around"
        flexWrap="wrap"
        width="100%"
      >
        {stats.map((stat, index) => (
          <FlexibleDiv
            key={index}
            flexDirection="column"
            alignItems="center"
            width="200px"
            margin="2rem 0"
          >
            <h2 style={{ color: colors.primary, fontSize: "2.5rem" }}>
              {stat.number}
            </h2>
            <p style={{ fontSize: "1.1rem", textAlign: "center" }}>
              {stat.label}
            </p>
          </FlexibleDiv>
        ))}
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

export default Stats; 