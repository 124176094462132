import React, { useState, useRef /* useEffect */ } from "react";
import emailjs from "@emailjs/browser";
import Blurb from "../../../../components/blurb/blurb.component.jsx";
import { colors } from "../../../../infrastructure/theme/colors.js";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component.jsx";
import { Button } from "../../../../components/button/button.component";
import { ToastContainer, toast } from "react-toastify";
// import {
//   HorizontalSpacer,
//   Spacer,
// } from "../../../../components/spacer/spacer.component.jsx";
// import { FaPlay as Icon } from "react-icons/fa";
import {
  StartAProjectContainer,
  InterestSection,
  InterestHeader,
  InterestHeaderText,
  InterestButtons,
  Form,
} from "./StartAProject.styles";
import { Input } from "../../../../components/input/input.component.jsx";

const StartAProject = () => {
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [selectedButtons, setSelectedButtons] = useState({});
  const [loading, setLoading] = useState(false);

  const handleFirst_name = (e) => setFirst_name(e.target.value);
  const handleLast_name = (e) => setLast_name(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePhone_number = (e) => setPhone_number(e.target.value);

  const handleInterestButtons = (event) => {
    const value = event.target.value;
    setSelectedButtons((prevState) => {
      if (prevState[value]) {
        const newState = { ...prevState };
        delete newState[value];
        return newState;
      } else {
        return { ...prevState, [value]: true };
      }
    });
    console.log("selectedButtons", Object.keys(selectedButtons));
  };

  //  Object.keys(selectedButtons);

  //   for (const selectedButton in selectedButtons) {
  //   console.log("selectedButtons", `${selectedButton}`);
  //   const newArray = []
  // }

  // SMTP.JS Code that was implemented but didn't work
  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   const config = {
  //     SecureToken: "3dee439c-977d-49c8-9236-bb5469e9e8dd",
  //     To: "blessedemoefe1122334455@gmail.com",
  //     From: `${email}`,
  //     Subject: "I am contacting you from itechnology terrain website",
  //     Body: `${first_name} ${last_name} Just sent a message. Take their phone number ${phone_number}`,
  //   };

  //   window.Email &&
  //     window.Email.send(config).then(() =>
  //       console.log("Message sent successfully",email,last_name,first_name,phone_number)
  //     );
  // };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        "itechnology_terrain",
        "template_39gum4s",
        form.current,
        "ZWVx3Mkh2TjRLZ2pf"
      )
      .then(
        (result) => {
          console.log("Result:", result.text, form.current);
          setSelectedButtons({});
          setFirst_name("");
          setLast_name("");
          setEmail("");
          setPhone_number("");
          setLoading(false);
          // const value = Object.keys(selectedButtons).split(",").join("-");
          // console.log(value);
          toast.success("Message sent, We will contact you soon!");
        },
        (error) => {
          console.log(error.text);
          toast.error("An Error occurred");
        }
      );
  };

  return (
    <StartAProjectContainer boxedLayout id="StartAProject">
      <FlexibleDiv
        alignItems="center"
        flexDirection="column"
        paddingVertical="0"
      >
        <Blurb width="70%">
          <h5>Start A Project</h5>
          <h3>Let's get you started!</h3>
          <p>
            Initiating your idea is simpler than you think. Let's get you
            started!
          </p>
        </Blurb>

        <FlexibleDiv
        // justifyContent="flex-start"
        // paddingVertical
        // width="40%"
        // background="pink"yar
        // alignItems="center"
        >
          <InterestSection>
            <InterestHeader>
              <InterestHeaderText lineHeight="max-content">
                Select Your Interests
              </InterestHeaderText>
            </InterestHeader>
            <InterestButtons>
              <Button
                btnText="Branding"
                background={colors.transparent}
                border={`1px solid ${colors.primary}`}
                padding={"0.5rem 1.2rem"}
                borderRadius={"30px"}
                btnColor={colors.primary}
                fontWeight="500"
                value="Branding"
                onClick={handleInterestButtons}
                className={selectedButtons["Branding"] ? "selected" : ""}
              />
              <Button
                btnText="Strategy"
                background={colors.transparent}
                border={`1px solid ${colors.primary}`}
                padding={"0.5rem 1.2rem"}
                borderRadius={"30px"}
                btnColor={colors.primary}
                fontWeight="500"
                value="Strategy"
                onClick={handleInterestButtons}
                className={selectedButtons["Strategy"] ? "selected" : ""}
              />
              <Button
                btnText="Naming and Idea Discovery"
                background={colors.transparent}
                border={`1px solid ${colors.primary}`}
                padding={"0.5rem 1.2rem"}
                borderRadius={"30px"}
                btnColor={colors.primary}
                fontWeight="500"
                onClick={handleInterestButtons}
                value="Naming and Idea Discovery"
                className={
                  selectedButtons["Naming and Idea Discovery"] ? "selected" : ""
                }
              />
              <Button
                btnText="UI/UX Design"
                background={colors.transparent}
                border={`1px solid ${colors.primary}`}
                padding={"0.5rem 1.2rem"}
                borderRadius={"30px"}
                value="UI/UX Design"
                btnColor={colors.primary}
                fontWeight="500"
                onClick={handleInterestButtons}
                className={selectedButtons["UI/UX Design"] ? "selected" : ""}
              />
              <Button
                btnText="Web Development"
                background={colors.transparent}
                border={`1px solid ${colors.primary}`}
                padding={"0.5rem 1.2rem"}
                borderRadius={"30px"}
                value="Web Development"
                btnColor={colors.primary}
                fontWeight="500"
                onClick={handleInterestButtons}
                className={selectedButtons["Web Development"] ? "selected" : ""}
              />
              <Button
                btnText="Mobile App development"
                background={colors.transparent}
                border={`1px solid ${colors.primary}`}
                padding={"0.5rem 1.2rem"}
                borderRadius={"30px"}
                value="Mobile App development"
                btnColor={colors.primary}
                fontWeight="500"
                onClick={handleInterestButtons}
                className={
                  selectedButtons["Mobile App development"] ? "selected" : ""
                }
              />
              <Button
                btnText="Documentation"
                background={colors.transparent}
                border={`1px solid ${colors.primary}`}
                padding={"0.5rem 1.2rem"}
                borderRadius={"30px"}
                value="Documentation"
                btnColor={colors.primary}
                fontWeight="500"
                onClick={handleInterestButtons}
                className={selectedButtons["Documentation"] ? "selected" : ""}
              />
              <Button
                btnText="Apply for Internship"
                background={colors.transparent}
                border={`1px solid ${colors.primary}`}
                padding={"0.5rem 1.2rem"}
                value="Apply for Internship"
                borderRadius={"30px"}
                btnColor={colors.primary}
                fontWeight="500"
                onClick={handleInterestButtons}
                className={
                  selectedButtons["Apply for Internship"] ? "selected" : ""
                }
              />
            </InterestButtons>
          </InterestSection>

          <Form ref={form} onSubmit={sendEmail}>
            <Input
              type="text"
              label="First Name"
              name={"first_name"}
              placeholder="Enter First Name"
              border={`1px solid ${colors.iconColor}`}
              // fontSize={fontSize}
              // borderRadius={borderRadius}
              value={first_name}
              onChange={handleFirst_name}
              width="47%"
            />
            <Input
              type="text"
              label="Last Name"
              name={"last_name"}
              placeholder="Enter Last Name"
              border={`1px solid ${colors.iconColor}`}
              value={last_name}
              onChange={handleLast_name}
              // fontSize={fontSize}
              // borderRadius={borderRadius}
              width="47%"
            />
            <Input
              type="email"
              label="Email"
              name={"email"}
              placeholder="Enter your email"
              border={`1px solid ${colors.iconColor}`}
              value={email}
              onChange={handleEmail}
              // fontSize={fontSize}
              // borderRadius={borderRadius}
              width="47%"
            />
            <Input
              type="tel"
              label="Phone Number"
              name={"phone_number"}
              placeholder="Enter Phone Number"
              border={`1px solid ${colors.iconColor}`}
              value={phone_number}
              onChange={handlePhone_number}
              // fontSize={fontSize}
              // borderRadius={borderRadius}
              width="47%"
            />
            <Input
              type="text"
              name={"interestedIn"}
              border={`1px solid ${colors.iconColor}`}
              value={Object.keys(selectedButtons)}
              display={"none"}
            />

            <Button
              btnText={loading ? "loading..." : "Claim your Discovery call"}
              border={`2px solid ${colors.primary}`}
              btnColor={colors.white}
              width="47%"
              // onClick={}
            />
          </Form>
        </FlexibleDiv>
        <ToastContainer />
      </FlexibleDiv>
    </StartAProjectContainer>
  );
};

export default StartAProject;
