import React from "react";
import Blurb from "../../../../components/blurb/blurb.component";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";
// import { VerticalCard } from "../../../../components/verticalCard/verticalCard.component";
// import H1 from "../../../../assets/images/h1.png";
import { CardSectionWrapper } from "./HowItWorks.styles";
import { SectionCards } from "../../../../components/sectionCards/sectionCards.component";
// import { Spacer } from "../../../../components/spacer/spacer.component";
import { colors } from "../../../../infrastructure/theme/colors";
import { SlUserUnfollow as User } from "react-icons/sl";
import { GiTimeTrap as Time } from "react-icons/gi";
import { CgTimelapse as WastingTime } from "react-icons/cg";
import { BsPatchCheck as Reliable } from "react-icons/bs";
import { GiTakeMyMoney as WastingMoney } from "react-icons/gi";
import { GiDeadEye as GreatIdea } from "react-icons/gi";

const HowItWorks = () => {
  const CardData = [
    {
      title: "DISCLOSING YOUR IDEA",
      description:
        "The goal is simple. Both the business and development teams must be on the same page.First, it's necessary to write down your long term vision. This general direction helping you to choose the right technologies for development.",
      Icon: User,
    },
    {
      title: "BRAINSTORMING",
      description:
        "When we try to get from A to B, maps are absolutely essential. That also applies to UX journeys when we are trying to engineer the best possible online experience. And that's where mind mapping comes in.",
      Icon: Reliable,
    },
    {
      title: "SKETCHING CONCEPT ",
      description:
        "Hand sketching is a fantastic way how to translate your ideas onto paper. A clear UX sketch unites the team. When everything is on paper, almost everyone in the company is able to imagine the app precisely, understand what they are trying to build.",
      Icon: GreatIdea,
    },
    {
      title: "DESIGNING THE GRAPHICS",
      description:
        "The goal of a graphic designer is to catch the users attention emotionally and support their smooth user experience. Secondly, the designer’s goal is to create a visual consistency across your whole project.",
      Icon: WastingTime,
    },
    {
      title: "CODING & PROGRAMMING",
      description:
        "For launching any application, I prefer to code the frontend but program only the most important parts of your MVPs. Why? If you don’t focus too much on the programming, you can launch your app prototype  faster - and that's the point.",
      Icon: Time,
    },
    {
      title: "WIREFRAMING",
      description:
        "Wireframes are normally created by a UX designer as a template for graphic designers to add the graphic elements. Great wireframes should be much more detailed than sketches and with more screens.",
      Icon: WastingMoney,
    },
  ];
  return (
    <FlexibleDiv
      justifyContent="center"
      paddingVertical="3rem"
      boxedLayout
      background={colors.bg.secondary}
      id="HowItWorks"
    >
      <Blurb width="70%">
        <h5>How It Works</h5>
        <h3>Start your worry-free branding project.</h3>
      </Blurb>
      <CardSectionWrapper>
        {CardData.map(({ title, description, Icon },idx) => (
          <SectionCards
          key={idx}
            titleSize={"1.5rem"}
            description={description}
            iconSize={"2rem"}
            iconColor={colors.black}
            color={colors.black}
            title={title}
            Icon={Icon}
            background={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e")`}
            border="0"
            space
            minHeight={"35vh"}
            bigger
          />
        ))}
      </CardSectionWrapper>
    </FlexibleDiv>
  );
};

export default HowItWorks;
