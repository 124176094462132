import React from "react";
import { Text } from "../../typography/text.component";
import { Spacer } from "../../spacer/spacer.component";
import { 
  RightSectionContainer,
  //  Logo 
  } from "./rightSection.styles";
// import logo from "../../../assets/images/logo-white.png";

export const RightSection = () => {
  const title = "Innovation Technology Terrain",
    description =
      "Innovation Technology Terrain aims to rediscover software products by collaborating with founders and companies inorder to aligned their vision into an exemplary software product";

  return (
    <RightSectionContainer paddingVertical="0">
      {/* <Logo src={logo} alt="logoImage" /> */}
      {/* <Spacer /> */}
      <Text
        color="white"
        fontWeight="600"
        fontSize="1.1rem"
        textAlign="left"
        resfontSize="1.3rem"
      >
        {title}
      </Text>
      <Spacer />
      <Text
        color="white"
        fontSize="0.9rem"
        textAlign="left"
        resfontSize="1rem"
      >
        {description}
      </Text>
    </RightSectionContainer>
  );
};
