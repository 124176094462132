import styled from "styled-components";
// import { colors } from "../../../infrastructure/theme/colors";
// import { Button } from "../../button/button.component";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
import { Text } from "../../typography/text.component";
// import { Input } from "../../input/input.component";

export const MiddleSectionWrapper = styled(FlexibleDiv)`
  width: 30%;
  min-width: 26rem;

  @media (max-width: 1280px) {
    width: 50%;
    min-width: 0;
  }
  @media (max-width: 1060px) {
    align-items: center;
    width: 100%;
    order: 2;
    margin-bottom: 4rem;
  }
  @media (max-width: 800px) {
    width: 100%;
    p {
      text-align: center;
    }
    input {
      width: 100%;
      margin-bottom: 1rem;
    }

    button {
      width: 100%;
    }
  }
`;
export const FooterText = styled(Text)`
  /* font-family: "Source Sans Pro", "Fira Sans", "Open Sans", "Helvetica Neue",
    sans-serif; */
  /* font-size:1rem; */
`;

export const Form = styled.form`
  height: ${({ height }) => (height ? height : "auto")};
  width: ${({ width }) => (width ? width : "100%")};
  background: ${({ background }) => (background ? background : "transparent")};
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "row"};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "space-between"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  padding: ${({ paddingVertical }) =>
      paddingVertical ? paddingVertical : "2.6rem"}
    ${({ boxedLayout }) => (boxedLayout ? "6%" : "0")};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : "wrap")};
  margin: 0;
  border: ${({ border }) => (border ? border : "none")};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "none"};

  @media (max-width: 1020px) {
    padding: ${({ paddingVertical }) =>
        paddingVertical ? paddingVertical : "1.6rem"}
      ${({ boxedLayout }) => (boxedLayout ? "3%" : "0")};
  }

  @media (max-width: 800px) {
    width: ${({ resWidth }) => (resWidth ? resWidth : "100%")};
    flex-direction: ${({ resFlexDirection }) =>
      resFlexDirection ? resFlexDirection : "column"};
    justify-content: ${({ justifyContent }) =>
      justifyContent ? justifyContent : "space-between"};
    align-items: center;
    padding: ${({ paddingVertical }) =>
        paddingVertical ? paddingVertical : "1  rem"}
      ${({ boxedLayout }) => (boxedLayout ? "5%" : "0")};
    flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : "wrap")};
  }
`;
