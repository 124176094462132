import React, { useState,useRef } from "react";
import { FlexibleDiv } from "../../flexibleDiv/flexibleDiv.component";
import emailjs from "@emailjs/browser";
// import { Text } from "../../typography/text.component";
import { Spacer } from "../../spacer/spacer.component";
import { Button } from "../../button/button.component";
// import { colors } from "../../../infrastructure/theme/colors";
import { Input } from "../../input/input.component";
import { ToastContainer, toast } from "react-toastify";
import {
  MiddleSectionWrapper,
  FooterText,
  // , FormInput,FormButton
  Form
} from "./middleSection.styles";

export const MiddleSection = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const handleSubscription = (e) => setEmail(e.target.value);

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      setLoading(true);
      emailjs
        .sendForm(
          "itechnology_terrain",
          "template_ckltj7y",
          form.current,
          "ZWVx3Mkh2TjRLZ2pf"
        )
        .then(
          (result) => {
            console.log("Result:", result.text, form.current);
            setEmail("");
            setLoading(false);
            // const value = Object.keys(selectedButtons).split(",").join("-");
            // console.log(value);
            toast.success("Thanks for Subscribing!");
          },
          (error) => {
            console.log(error.text);
            toast.error("An Error occurred");
          }
        );
    };

  return (
    <MiddleSectionWrapper
      paddingVertical="0"
      alignItems="flex-start"
      flexDirection="column"
    >
      {/* <FlexibleDiv paddingVertical="0"> */}
      <FooterText
        color="white"
        fontWeight="600"
        fontSize="1.1rem"
        resfontSize="1.3rem"
      >
        Our Newsletter
      </FooterText>
      <Spacer size="small" />
      <FooterText
        color="white"
        textAlign="left"
        resTextAlign="left"
        resfontSize="1.1rem"
      >
        Don’t miss any future updates of our new template and extensions and all
        the astonishing offers.
      </FooterText>
      <Spacer size="small" />
      <FlexibleDiv paddingVertical="0">
        <Form ref={form} onSubmit={sendEmail}>
          <Input
            footerInput="70%"
            type="text"
            placeholder="youremailaddress@gmail.com"
            width="70%"
            value={email}
            name={"email"}
            onChange={handleSubscription}
            NoExternalPadding
          />

          <Button
            btnText={loading ? "Loading..." : "Subscribe"}
            width="27%"
            padding="1.2rem 0.5rem"
          />
        </Form>
      </FlexibleDiv>
      <Spacer size="small" />
      {/* </FlexibleDiv> */}
      <ToastContainer />
    </MiddleSectionWrapper>
  );
};
