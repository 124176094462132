import React from "react";
import { FlexibleDiv } from "../../components/flexibleDiv/flexibleDiv.component";
import Blurb from "../../components/blurb/blurb.component";
import { Spacer } from "../../components/spacer/spacer.component";
import { colors } from "../../infrastructure/theme/colors";
import Hero from "./Sections/Hero/hero.section";
import Stats from "./Sections/Stats/stats.section";
import Team from "./Sections/Team/team.section";

const About = () => {

  const values = [
    {
      id: 1,
      title: "Excellence",
      description:
        "We strive for excellence in everything we do, from our curriculum to our teaching methods.",
    },
    {
      id: 2,
      title: "Innovation",
      description:
        "We embrace new technologies and teaching methods to provide the best learning experience.",
    },
    {
      id: 3,
      title: "Inclusivity",
      description:
        "We believe in creating opportunities for everyone, regardless of their background.",
    },
  ];

  return (
    <FlexibleDiv flexDirection="column" width="100%">
      <Hero />
      <Stats />

      {/* Mission Section */}
      <FlexibleDiv
        boxedLayout
        paddingVertical="5rem"
        background={colors.bg.secondary}
      >
        <FlexibleDiv
          paddingVertical="0"
          flexDirection="column"
          alignItems="flex-start"
          width="80%"
        >
          <Blurb width="100%">
            <h3>Our Mission</h3>
            <p style={{ fontSize: "1.1rem", lineHeight: "1.8" }}>
              We are dedicated to bridging the digital skills gap by providing
              accessible, high-quality tech education. Through our partnerships with
              industry leaders like Google, Pluralsight, and PIND, we create
              opportunities for aspiring tech professionals to build successful
              careers in technology.
            </p>
          </Blurb>
        </FlexibleDiv>
      </FlexibleDiv>

      {/* Values Section */}
      <FlexibleDiv boxedLayout paddingVertical="5rem">
        <FlexibleDiv
          paddingVertical="0"
          flexDirection="column"
          alignItems="center"
          width="80%"
        >
          <Blurb width="100%" alignItems="center" textAlign="center">
            <h3>Our Values</h3>
          </Blurb>
          <Spacer size="medium" />
          <FlexibleDiv
            paddingVertical="0"
            justifyContent="center"
            flexWrap="wrap"
            gap="2rem"
          >
            {values.map((value) => (
              <ValueCard key={value.id} {...value} />
              // <p>{value.title} </p>
            ))}
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>

      <Team />
    </FlexibleDiv>
  );
};

const ValueCard = ({ title, description }) => (
  <FlexibleDiv
    width="300px"
    padding="2rem"
    flexDirection="column"
    alignItems="flex-start"
    background={colors.bg.secondary}
    borderRadius="10px"
  >
    <h4 style={{ marginBottom: "1rem", color: colors.primary }}>
      {title}
    </h4>
    <p style={{ lineHeight: "1.6" }}>{description}</p>
  </FlexibleDiv>
);



export default About;
